/*------Основной стиль для модального окна----------------------------------------------------------------------------*/
.sorting-modal {
    position: absolute;
    max-width: 456px;
    max-height: 427px;
    background-color: #FFFFFF;
    top: 180px;
    left: 0;
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    transition: 0.5s;
    box-shadow: 0 4px 8px 0 rgba(54, 59, 77, 0.1);
    transform: translate(-100%);
}
.sorting-modal.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
}
/*------Стилизация для контента модального окна------------------------------------------------------------------------*/
.sorting-modal__content {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 32px 32px;
    flex-direction: column;
    background-color: #FFFFFF;
    position: relative;
    transform: scale(0.5);
    transition: 0.4s all;
    gap: 12px;
}
.sorting-modal__content.active {
    transform: scale(1);

}
.sorting-modal__content-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
}
.sorting-modal__content-title h3 {
    line-height: 24px;
    font-weight: 600;
    font-size: 18px;
    color: #363B4D;
    opacity: 90%;
}
.sorting-modal__content-title img {
    cursor: pointer;
    width: 12px;
    height: 12px;

}
/*------Стилизация для всех div c типом сортировки------------------------------------------------------------------------*/
.sorting-modal__types-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.sorting-modal__types-sort span {
    font-weight: 400;
    line-height: 110%;
    color: #363B4D;
    opacity: 90%;
    padding-right: 26px;
    white-space: nowrap;
}
.sorting-modal__types-sort-btn-img {
    cursor: pointer;
}
.sorting-modal__types-sort-btn-img:hover {
    background-color: rgba(54, 59, 77, 0.4);
    border-radius: 4px;
}
.sorting-modal__types-sort-btn-img__active {
    background-color: rgba(54, 59, 77, 0.4);
    border-radius: 4px;
    pointer-events: none;
    cursor: default;
}
.types-sort__decrease-increase-btn {
    display: inline-flex;
    gap: 8px;
}
.sorting-modal__mobile-buttons {
   display: none;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .sorting-modal__content {
        padding: 16px 16px;
    }
}
@media (max-width: 767px) {
    .sorting-modal.active {
        left: 0;
        font-size: 14px;
    }
    .sorting-modal__content-title h3 {
        font-size: 16px;
    }
}
@media (max-width: 470px) {
    .sorting-modal {
        top: 180px;
        left: -1px;
        width: 375px;
        max-height: 397px;
        position: fixed;
    }
    .sorting-modal__content {
        padding: 24px 16px;
        gap: 18px;

    }
    .sorting-modal__content-title h3 {
        font-size: 12px;
        line-height: 18px;
        color: rgba(54, 59, 77, 0.4);
        padding-bottom: 0;
        font-weight: 400;
    }
    .sorting-modal__types-sort {
        font-size: 14px;
    }
    .sorting-modal__mobile-buttons {
        display: inline-flex;
        gap: 15px;
}
    .sorting-modal__mobile-buttons-default-filter {
        width: 178px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px #0084FE solid;
        cursor: pointer;
        color: #0084FE;
        font-size: 14px;
    }
    .sorting-modal__mobile-buttons-apply {
        width: 150px;
        height: 40px;
        border-radius: 4px;
        background: #0084FE;
        color: #FFFFFF;
        font-size: 14px;
        cursor: pointer;
    }
    .sorting-modal__types-sort img {
        width: 28px;
        height: 28px;
    }
    .sorting-modal__content-title img {
        display: initial;
    }
}
